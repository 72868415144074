import React, {ReactElement} from 'react';
import classNames from "classnames";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useId from "@/hooks/useId";
import "react-tooltip/dist/react-tooltip.css"; // Import tooltip styles if needed

export interface OverlapItem {
  image: string | ReactElement;
  id: string; // Used for alt text
  tooltip: string | ReactElement;
  boosted?: boolean;
}

interface Props {
  items: OverlapItem[];
}

const translateClasses = [
  'group-hover:translate-x-0',
  'group-hover:translate-x-3',
  'group-hover:translate-x-6',
  'group-hover:translate-x-9',
  'group-hover:translate-x-12', // Extend for more items if needed
];

export const OverlappingImages: React.FC<Props> = ({ items }) => {
  const id = useId();

  return (
    <div className="flex justify-start items-center relative group">
      {items.map((item, i) => {
        const itemId = `overlap_${id}_${item.id}`;
        return (
          <>
            {
              typeof item.image === "string" ?
                <img
                  key={itemId}
                  id={itemId}
                  src={item.image}
                  alt={item.id}
                  data-tooltip-id={itemId} // Add this attribute to bind tooltip
                  className={classNames(
                    `w-6 h-6 object-cover transition-transform duration-300 ease-in-out ${translateClasses[i]}`,
                    {
                      "-ml-2": i > 0,
                    }
                  )}
                /> :
                <div
                  key={itemId}
                  id={itemId}
                  data-tooltip-id={itemId} // Add this attribute to bind tooltip
                  className={classNames(
                    `w-6 h-6 object-cover transition-transform duration-300 ease-in-out ${translateClasses[i]}`,
                    {
                      "-ml-2": i > 0,
                    }
                  )}>
                  {item.image}
                </div>
            }
            <ReactTooltip
              key={itemId + "_tooltip"}
              anchorSelect={`#${itemId}`} // Use the same id for ReactTooltip
              place="bottom"
              className="z-50 bg-gray-700 text-gray-300"
            >
              {item.tooltip}
            </ReactTooltip>
          </>
        );
      })}
    </div>
  );
};
