import classNames from "classnames";

export interface Props {
  boost: number;
  color: "green" | "yellow"
}

export const BoostImage = ({boost, color}: Props) => {
  return (
    <div className={classNames("h-6 w-6 flex items-center justify-center rounded-full border-2 bg-black text-xs font-bold", {
      "border-[#94FF0B] text-[#94FF0B]": color === "green",
      "border-[#FCFC03] text-[#FCFC03]": color === "yellow"
    })}>
      {boost}x
    </div>
  )
}